import { useEffect, useState } from 'react';
import { StatusBar } from '@capacitor/status-bar';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Main from 'apps/main';
import { Capacitor } from '@capacitor/core';
import './App.css';
import './index.css'

const App: React.FC = () => {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      // Afficher la status bar
      StatusBar.show();

      // Définir la couleur de fond de la barre de statut pour iOS
      StatusBar.setBackgroundColor({ color: '#ffffff' });

      // S'assurer que le contenu de l'application commence sous la status bar
      StatusBar.setOverlaysWebView({ overlay: true }).then(() => {
        console.log('Overlay de la status bar activé');
      });
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
