/**
 *  Microtaskers
 *  Nom: ANDRIARILALAO Johny Lino
 *  Email: johny.andriarilalao@gmail.com
 */

import React, { lazy, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { SuspenseView } from 'public/shared/components/SuspenseView'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppRoutes: React.FC = () => {
  const SplashScreen = lazy(() => import('public/modules/splashScreens'))
  const AuthPage = lazy(() => import('public/modules/auth'))
  const AdminRoutes = lazy(() => import('./AdminRoutes'))
  const PrivatesRoutes = lazy(() => import('./PrivatesRoutes'))


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route
              path="admin/*"
              element={
                <SuspenseView>
                  <AdminRoutes />
                </SuspenseView>
              }
            />
            <Route
              path="/*"
              element={
                <SuspenseView>
                  <PrivatesRoutes />
                </SuspenseView>
              }
            />
            <Route path="*" element={<Navigate to="/admin/auth/login" replace={true} />} />
            {/* {firstConnected ? (
              <>
                <Route
                  path="splash/*"
                  element={
                    <SuspenseView>
                      <SplashScreen setConnected={setConnected} />
                    </SuspenseView>
                  }
                />
                <Route path="*" element={<Navigate to="splash/one" />} />
              </>
            ) : connected ? (
              <> <Route
                path="/*"
                element={
                  <SuspenseView>
                    <AdminRoutes />
                  </SuspenseView>
                }
              />
                <Route path="public/*" element={<SuspenseView><PrivatesRoutes /></SuspenseView>} />
                <Route index element={<Navigate to="public/home" replace={true} />} />
              </>
            ) : (
              <>


                <Route
                  path="auth/*"
                  element={
                    <SuspenseView>
                      <AuthPage />
                    </SuspenseView>
                  }
                />
                <Route
                  path="*"
                  element={<Navigate to="/auth" replace={true} />}
                />
              </>
            )} */}
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={10000} />
    </>
  )
}

export default AppRoutes
